.text-primary {
  color: #0c668b !important;
}

.text-secondary {
  color: #56a1cb !important;
}

.text-gray {
  color: #b9b9b9 !important;
}