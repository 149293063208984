@import "~antd/dist/antd.less";

// DONT MAKE CLASS NAME LIKE TAILWIND CLASS check https://tailwindcss.com/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  margin-bottom: 0;
}

.content-container {
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 40px 100px rgba(68, 68, 79, 0.05);
  border-radius: 10px;
}

.content-center {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 16px;
  z-index: 1;
  width: 480px;
  box-shadow: 0px 40px 100px rgba(68, 68, 79, 0.1);
}

.bg-image {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background-image: url(assets/image/bg-login.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-datatable {
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 40px 100px rgba(68, 68, 79, 0.05);
  border-radius: 10px;
}

.b-secondary {
  width: 100px;
  height: 2px;
  background: #56a1cb;
}

.b-gray-full {
  width: 100px;
  height: 2px;
  background: #f3f3f3;
}

.btn-default {
  height: 40px;
  width: 140px;
}

.btn-secondary {
  color: black;
  background-color: #f3f3f3;
  border: 0;
  height: 40px;
  width: 140px;
}

.btn-secondary:hover {
  background-color: #f5fbff;
  color: #56a1cb;
}

.custom-btn {
  border: 0;
  height: 40px;
  width: 140px;
  color: #0c668b;
  background-color: #f5fbff;
}

.custom-btn:hover {
  border: 1px solid #56a1cb;
}

.filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  width: 132px;
  height: 41px;
  border-radius: 10px;
  cursor: pointer;
}

.filter-btn:hover {
  border: 1px solid #56a1cb;
}

.drop-photo {
  background: black;
  overflow: hidden;
  border-radius: 10px;
}

.select-bg-gray {
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: aquamarine !important;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-image-preview-img-wrapper {
  transform: translate3d(0px, 0px, 0px) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s !important;
}
// DONT MAKE CLASS NAME LIKE TAILWIND CLASS check https://tailwindcss.com/

@layout-body-background: #FCFCFD;@primary-color: #0C668B;@border-radius-base: 10px;@btn-primary-shadow: 0px 5px 30px rgba(12, 102, 139, 0.25);@switch-color: #319E34;@primary-1: #FAFAFA;@btn-height-lg: 50px;@error-color: #E23A0F;@input-hover-border-color: #56A1CB;@checkbox-border-radius: 4px;@checkbox-color: #56A1CB;@link-color: #56A1CB;@tag-border-radius: 5px;