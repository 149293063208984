/* Ant Typography */
.ant-typography {
  margin: 0 !important;
}

h4.ant-typography {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-typography.ant-typography-secondary {
  color: #737373 !important;
}

.ant-popover-title {
  border-bottom: 0px !important;
}

/* Antd Layout */
.site-layout .site-layout-background {
  background: #fff;
}

.ant-table-thead > tr > th {
  color: grey !important;
  background: #fff !important;
}

.ant-dropdown-menu {
  border-radius: 15px;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-tag {
  border: 0 !important;
  font-weight: 500;
}

.ant-tag-green {
  color: #319e34 !important;
  background: #edffed !important;
}

.ant-tag-blue {
  color: #56a1cb !important;
  background: #f5fbff !important;
}

.custom-radio-btn {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.custom-radio-btn-selected {
  border: 1px solid #56a1cb;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-collapse-content {
  background-color: #fcfcfd !important;
}

.ant-menu-sub.ant-menu-inline {
  background: #fff !important;
}

.ant-pagination-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.custom-radio .ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  top: 0em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.ant-radio-wrapper {
  margin-right: 0px !important;
}

.ant-pagination-item {
  border-radius: 5px !important;
}

.ant-pagination-item-active a {
  background: #0c668b !important;
  color: white !important;
}

.ant-btn {
  font-weight: 500 !important;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: cover !important;
}

.search-input .ant-input-group-addon {
  background-color: white;
  width: 50px;
}

.search-input .ant-input-group-addon:first-child {
  background-color: white;
}

.search-input .ant-input-group-addon:last-child {
  background-color: white;
  height: 40px !important;
}

.search-input .ant-input-group-addon,
.ant-input-group-wrap {
  vertical-align: baseline !important;
}

.rdrCalendarWrapper {
  width: 100% !important;
}
.rdrMonth {
  width: 100% !important;
}

.custom-checkbox .ant-checkbox-wrapper {
  flex-direction: row-reverse;
}

.custom-checkbox .ant-checkbox-inner {
  border-radius: 50% !important;
}

.table-invoice-order .ant-table-cell {
  white-space: nowrap !important;
}

.select-bg-gray
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f3f3f3 !important;
}

.select-bg-gray .ant-select-selection-item {
  font-weight: 500;
}

.tab-mywallet .ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
}

.anticon {
  vertical-align: 0.1rem !important;
}
